<template>
  <div id="order-show-page">
    <!-- <vs-alert :color="getOrderStatusColor(order.status)">
      <span :class="order.status === 'sent' ? 'text-white' : ''">Order is {{ order.status }}.</span>
    </vs-alert> -->
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <template v-else>
      <vs-row class="order-header mb-4 mt-4" v-if="$acl.check('operator')">
        <h1>{{ $t('order') }}: #{{ order.code }}</h1>
        <vs-button class="ml-auto" @click.prevent="$router.push({name: 'orderInvoice', params: {id: order.uuid}})">
          <feather-icon icon="FileTextIcon" svgClasses="w-5 h-5 stroke-current" />
        </vs-button>
        <vs-button class="d-none ml-4" @click.prevent="flaskPrint">
          <feather-icon icon="PrinterIcon" svgClasses="w-5 h-5 stroke-current" />
        </vs-button>
        <vs-button class="hidden ml-4" @click.prevent="thermalPrint">
          Thermal
        </vs-button>
        <vs-button v-if="order.status==='pending'" class="ml-4" @click.prevent="confirm('accept', order.code, order.uuid)">{{ $t('orderPage.accept') }}</vs-button>
        <vs-button v-if="order.status==='pending'" color="danger" type="border" class="ml-4" @click.prevent="confirmReject(order.code, order.uuid)">{{ $t('orderPage.reject') }}</vs-button>
        <vs-button v-if="order.status==='accepted'" color="primary" class="ml-4" @click.prevent="sentOrder(order.uuid)">{{ $t('orderPage.sendTo') }}</vs-button>
        <vs-button v-if="order.status==='sent'" color="primary" class="ml-4" @click.prevent="confirm('deliver', order.code, order.uuid)">{{ $t('orderPage.deliver') }}</vs-button>
        <vs-button v-if="order.status==='sent'" color="danger" class="ml-4" @click.prevent="confirmNotDeliver(order.code, order.uuid)">{{ $t('orderPage.notDeliver') }}</vs-button>
      </vs-row>
      <vs-row class="order-details">
        <div class="vs-col w-full lg:w-1/2 lg:pr-4">
          <h3 class="mb-3">{{ $t('orderDetails') }}</h3>
          <vx-card class="mt-6">
            <vs-table hoverFlat stripe :data="Object.keys(order)">
              <template slot="thead">
                <vs-th>{{$t('property')}}</vs-th>
                <vs-th>{{$t('value')}}</vs-th>
              </template>
              <template>
                <vs-tr>
                  <vs-td><span class="uppercase font-medium">{{ $t('props.code') }}:</span></vs-td>
                  <vs-td class="font-semibold">#{{ order.code }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td><span class="uppercase font-medium">{{ $t('props.totalPrice') }}:</span></vs-td>
                  <vs-td><strong>{{ order.total_price }}TMT</strong></vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td><span class="uppercase font-medium">{{ $t('props.payment') }}:</span></vs-td>
                  <vs-td><span class="uppercase">{{ order.payment_type }}</span></vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td><span class="uppercase font-medium">{{ $t('props.deliveryType') }}:</span></vs-td>
                  <vs-td><span class="uppercase">{{ order.delivery_type }}</span></vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td><span class="uppercase font-medium">{{ $t('props.deliveryCost') }}:</span></vs-td>
                  <vs-td class="font-semibold">{{ order.delivery_cost }}TMT</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td><span class="uppercase font-medium">{{ $t('props.deliveryTime') }}:</span></vs-td>
                  <vs-td class="">{{ order.delivery_time }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td><span class="uppercase font-medium">{{ $t('status') }}:</span></vs-td>
                  <vs-td><vs-chip :color="getOrderStatusColor(order.status)">{{ $t(`orderPage.${order.status}`) }}</vs-chip></vs-td>
                </vs-tr>
                <vs-tr v-if="order.actionAt">
                  <vs-td><span class="uppercase font-medium">{{ $t(`orderPage.${order.status}`) }}:</span></vs-td>
                  <vs-td>{{ new Date(order.actionAt).toLocaleString('ru-RU') }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td><span class="uppercase font-medium">{{ $t('props.createdAt') }}:</span></vs-td>
                  <vs-td>{{ new Date(order.createdAt).toLocaleString('ru-RU') }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td><span class="uppercase font-medium">{{ $t('props.isSynced') }}:</span></vs-td>
                  <vs-td>
                    <vs-chip :color="order.isSynced ? 'primary' : 'danger'">
                      <feather-icon v-if="order.isSynced" icon="CheckIcon" svgClasses="w-5 h-5" />
                      <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
                    </vs-chip>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vx-card>
        </div>
        <div class="vs-col w-full lg:w-1/2 lg:pl-4">
          <h4 class="mb-4">{{ $t('userDetails') }}</h4>
          <vx-card class="mt-6">
            <vs-table hoverFlat stripe :data="Object.keys(order)">
              <template slot="thead">
                <vs-th>{{$t('property')}}</vs-th>
                <vs-th>{{$t('value')}}</vs-th>
              </template>
              <template>
                <vs-tr>
                  <vs-td><span class="uppercase font-medium">ID:</span></vs-td>
                  <vs-td>{{ order.userId }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td><span class="uppercase font-medium">{{ $t('fields.name') }}:</span></vs-td>
                  <vs-td>{{ order.user_name }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td><span class="uppercase font-medium">{{ $t('fields.phone') }}:</span></vs-td>
                  <vs-td>{{ order.user_phone }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td><span class="uppercase font-medium">{{ $t('fields.address') }}:</span></vs-td>
                  <vs-td>{{ order.address }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td><span class="uppercase font-medium">{{ $t('orderPage.rating') }}:</span></vs-td>
                  <vs-td>
                    <span class="mr-3">{{ order.rating }}</span>
                    <template v-if="order.rating">
                      <feather-icon v-for="i in Math.floor(order.rating)" :key="i" icon="StarIcon" svgClasses="w-4 h-4 text-warning" :title="$t('orderPage.deliver')" class="mr-1" />
                      <feather-icon v-for="i in 5 - Math.floor(order.rating)" :key="`f${i}`" icon="StarIcon" svgClasses="w-4 h-4" :title="$t('orderPage.deliver')" class="mr-1" />
                    </template>
                    <template v-else>
                      <feather-icon v-for="i in 5" :key="i" icon="StarIcon" svgClasses="w-4 h-4" :title="$t('orderPage.deliver')" class="mr-1" />
                    </template>
                  </vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td><span class="uppercase font-medium">{{ $t('orderPage.userComment') }}:</span></vs-td>
                  <vs-td>{{ order.comment_by_user }}</vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <h4 class="mt-4 mb-3">{{ $t('courierDetails') }}</h4>
            <vs-table hoverFlat stripe :data="Object.keys(order)">
              <template slot="thead">
                <vs-th>{{$t('property')}}</vs-th>
                <vs-th>{{$t('value')}}</vs-th>
              </template>
              <template>
                <vs-tr>
                  <vs-td><span class="uppercase font-medium">ID:</span></vs-td>
                  <vs-td>{{ order.carrierId }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td><span class="uppercase font-medium">NOTE:</span></vs-td>
                  <vs-td><span class="font-medium" :class="order.status === 'rejected' || order.status === 'not_delivered' ? 'text-danger' : ''">{{ order.note }}</span></vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vx-card>
        </div>
        <div class="w-full mt-3">
          <div class="flex">
            <h3 class="mt-2 mb-4">{{ $t('orderItems') }}</h3>
            <vs-button v-if="order.status==='pending' || order.status==='accepted'" class="ml-auto mb-4" @click.prevent="addOrderItem">{{ $t('add_new') }}</vs-button>
          </div>
          <vx-card>
            <vs-table hoverFlat ref="table" :data="orderItems" stripe>
              <template slot="thead">
                <vs-th class="w-3"></vs-th>
                <vs-th class="w-4">{{ $t('fields.image') | capitalize }}</vs-th>
                <vs-th>{{ $t('fields.code') }}</vs-th>
                <vs-th>{{ $t('fields.shelf_code') }}</vs-th>
                <vs-th>{{ $t('fields.bar_code') }}</vs-th>
                <vs-th>{{ $t('fields.title') }}</vs-th>
                <vs-th>{{ $t('fields.price') }}</vs-th>
                <vs-th>{{ $t('quantity') }}</vs-th>
                <vs-th>{{ $t('fields.total') }}</vs-th>
                <vs-th v-if="order.status === 'pending' || order.status === 'accepted'">{{ $t('fields.actions') }}</vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="pt-3">
                  <vs-td class="w-3 m-0 pr-0">
                    <feather-icon v-if="data[indextr].type === 'product'" icon="BoxIcon" svgClasses="stroke-current, w-3, h-3, hover:text-primary" />
                    <feather-icon v-else icon="ArchiveIcon" svgClasses="stroke-current, w-3, h-3, hover:text-primary" />
                  </vs-td>
                  <vs-td>
                    <div class="img w-20 h-20 overflow-hidden">
                      <img v-if="data[indextr].product_images && data[indextr].product_images.length" :src="`${$baseUrl}/products/preview/${data[indextr].product_images[0].uuid}.webp`" alt="img" class="w-full h-full">
                      <img v-else-if="data[indextr].images && data[indextr].images.length" :src="`${$baseUrl}/collections/preview/${data[indextr].images[0].uuid}.webp`" alt="img" class="w-full h-full">
                      <p v-else class="text-black m-1">No image!</p>
                    </div>
                  </vs-td>
                  <vs-td :data="data[indextr].code">
                    {{ data[indextr].code }}
                  </vs-td>
                  <vs-td :data="data[indextr].shelf_code">
                    {{ data[indextr].shelf_code }}
                  </vs-td>
                  <vs-td :data="data[indextr].bar_code">
                    {{ data[indextr].bar_code }}
                  </vs-td>
                  <template v-if="data[indextr].type === 'product'">
                    <vs-td :data="data[indextr][`name_${$i18n.locale}`]">
                      {{ data[indextr][`name_${$i18n.locale}`] }}
                      <template v-if="data[indextr]['Orderproducts'].discount_amount || data[indextr]['Orderproducts'].discount_required">
                        ({{ data[indextr]['Orderproducts'].discount_type !== 'bonused' ? -data[indextr]['Orderproducts'].discount_amount : `${data[indextr]['Orderproducts'].discount_required} + ${data[indextr]['Orderproducts'].discount_bonus}`}}
                        {{ data[indextr]['Orderproducts'].discount_type === 'price' ? 'm' : data[indextr]['Orderproducts'].discount_type === 'percentage' || data[indextr]['Orderproducts'].discount_type === 'promo' ? '%' : ''}})
                      </template>
                    </vs-td>
                    <vs-td :data="data[indextr]['Orderproducts'].price">
                      {{ data[indextr]['Orderproducts'].price.toFixed(2) }}TMT
                    </vs-td>
                    <vs-td :data="data[indextr]['Orderproducts'].quantity">
                      {{ data[indextr]['Orderproducts'].quantity }}
                    </vs-td>
                    <vs-td :data="data[indextr]['Orderproducts'].total_price">
                      {{ data[indextr]['Orderproducts'].total_price.toFixed(2) }}TMT
                    </vs-td>
                    <vs-td v-if="order.status === 'pending' || order.status === 'accepted'" class="whitespace-no-wrap">
                      <feather-icon v-if="$acl.check('operator') && (order.status === 'pending' || order.status === 'accepted')" icon="TrashIcon" :svgClasses="['stroke-current', 'w-5', 'h-5', 'hover:text-danger' ]" class="ml-2" :title="$t('delete')" @click.stop="confirmDeleteItem(tr['Orderproducts'].uuid, tr.type)" />
                    </vs-td>
                  </template>
                  
                  <template v-else-if="data[indextr].type === 'collection'">
                    <vs-td :data="data[indextr][`name_${$i18n.locale}`]">
                      {{ data[indextr][`name_${$i18n.locale}`] }}
                      <template v-if="data[indextr]['Ordercollections'].discount_amount || data[indextr]['Ordercollections'].discount_required">
                        ({{ data[indextr]['Ordercollections'].discount_type !== 'bonused' ? -data[indextr]['Ordercollections'].discount_amount : `${data[indextr]['Ordercollections'].discount_required} + ${data[indextr]['Ordercollections'].discount_bonus}`}}
                        {{ data[indextr]['Ordercollections'].discount_type === 'price' ? 'm' : data[indextr]['Ordercollections'].discount_type === 'percentage' ? '%' : ''}})
                      </template>
                    </vs-td>
                    <vs-td :data="data[indextr]['Ordercollections'].price">
                      {{ data[indextr]['Ordercollections'].price.toFixed(2) }}TMT
                    </vs-td>
                    <vs-td :data="data[indextr]['Ordercollections'].quantity">
                      {{ data[indextr]['Ordercollections'].quantity }}
                    </vs-td>
                    <vs-td :data="data[indextr]['Ordercollections'].total_price">
                      {{ data[indextr]['Ordercollections'].total_price.toFixed(2) }}TMT
                    </vs-td>
                    <vs-td v-if="order.status === 'pending' || order.status === 'accepted'" class="whitespace-no-wrap">
                      <feather-icon v-if="$acl.check('operator') && (order.status === 'pending' || order.status === 'accepted')" icon="TrashIcon" :svgClasses="['stroke-current', 'w-5', 'h-5', 'hover:text-danger' ]" class="ml-2" :title="$t('delete')" @click.stop="confirmDeleteItem(tr['Ordercollections'].uuid, tr.type)" />
                    </vs-td>
                  </template>

                  <!-- <vs-td class="whitespace-no-wrap"> -->
                    <!-- <feather-icon v-if="data[indextr].type === 'product'" icon="EyeIcon" :svgClasses="['stroke-current', 'w-5', 'h-5', 'hover:text-primary' ]" title="View product details" @click.stop="$router.push({name: 'productShow', params: { id: tr['Orderproducts'].uuid }} )" /> -->
                    <!-- <feather-icon v-if="$acl.check('operator')" icon="EditIcon" :svgClasses="['stroke-current', 'w-5', 'h-5', 'hover:text-primary' ]" class="ml-2" title="Edit brand details" @click.stop="editData(tr, tr.type)" /> -->
                    <!-- <feather-icon v-if="$acl.check('operator') && order.status === 'pending'" icon="TrashIcon" :svgClasses="['stroke-current', 'w-5', 'h-5', 'hover:text-danger' ]" class="ml-2" :title="$t('delete')" @click.stop="deleteItem(tr['Orderproducts'].uuid, tr.type)" /> -->
                    <!-- <feather-icon v-if="$acl.check('operator') && (order.status === 'pending' || order.status === 'accepted')" icon="TrashIcon" :svgClasses="['stroke-current', 'w-5', 'h-5', 'hover:text-danger' ]" class="ml-2" :title="$t('delete')" @click.stop="deleteItem(tr['Orderproducts'].uuid, tr.type)" /> -->
                    <!-- <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.uuid)" /> -->
                  <!-- </vs-td> -->
                </vs-tr>
              </template>

            </vs-table>
          </vx-card>
        </div>
        <!-- {{ discount }}<br> -->
        <!-- {{ subtotal }} -->
      </vs-row>
      <vs-popup :active.sync="activePrompt" variant="danger" :title="isRejecting ? $t('orderPage.reject') : isNotDelivered ? $t('orderPage.notDeliver') : $t('orderPage.sendTo')">
        <div class="con-exemple-prompt">

          <template v-if="isRejecting || isNotDelivered">
            <p class="mb-3">{{$t('enterReason')}}:</p>
            <vs-textarea v-validate="'required'" name="reason" v-model="note" :placeholder="$t('reason')" />
            <span class="text-danger text-sm" v-show="errors.has('reason')">{{ errors.first('reason') }}</span>
            <vs-row class="mt-6">
              <vs-button @click="rejectOrder" color="danger">{{isRejecting ? $t('reject') : $t('orderPage.notDeliver')}}</vs-button>
              <vs-button @click="cancel" color="primary" type="border" class="ml-auto">{{$t('cancel')}}</vs-button>
            </vs-row>
          </template>

          <template v-else-if="addingItem">
            <vs-row>
              <ul class="flex w-full mt-2 mb-4">
                <li class="pr-5">
                  <vs-radio v-validate="'required'" name="item_type" v-model="itemType" vs-value="product">{{ $t('product') }}</vs-radio>
                </li>
                <li class="pr-5">
                  <vs-radio v-validate="'required'" name="item_type" v-model="itemType" vs-value="collection">{{ $t('comboProduct') }}</vs-radio>
                </li>
              </ul>
              <span class="text-danger text-sm" v-show="errors.has('item_type')">{{ errors.first('item_type') }}</span>
            </vs-row>
            <vs-row v-if="itemType">
              <div class="w-full">
                <v-select v-validate="'required'" :label="`name_${$i18n.locale}`" :options="products" name="item" id="item" :placeholder="$t('selectOne')" v-model="selectedItem" @search="getItems" />
                <span class="text-danger text-sm" v-show="errors.has('item')">{{ errors.first('item') }}</span>
              </div>
              <vs-input class="mt-4 w-full" v-validate="'required'" name="quantity" type="number" id="quantity" v-model="itemQt" />
              <span class="text-danger text-sm" v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
            </vs-row>
            <vs-row class="mt-24">
              <vs-button @click="submitAddItem">{{ $t('add_new') }}</vs-button>
              <vs-button @click="cancel" color="danger" type="border" class="ml-auto">{{$t('cancel')}}</vs-button>
            </vs-row>
          </template>

          <template v-else>
            <v-select v-validate="'required'" label="name" :options="filteredCarriers" name="carrier" :placeholder="$t('selectOne')+' '+$t('courier')" v-model="selectedCarrier" :reduce="carrier => carrier.id" />
            <span class="text-danger text-sm" v-show="errors.has('carrier')">{{ errors.first('carrier') }}</span>
            <vs-row class="mt-24">
              <vs-button @click="submitSent">{{ $t('sent') }}</vs-button>
              <vs-button @click="cancel" color="danger" type="border" class="ml-auto">{{$t('cancel')}}</vs-button>
            </vs-row>
          </template>
        </div>
      </vs-popup>
      <div class="invoice-print-text">
        <h4 class="text-center text-bold">Magnit Market</h4>
        <p class="text-center mb-3" style="font-weight: 600;">Onlaýn market siziň jübiňizde. Müňlerçe haryt diňe bizde.</p>
        <p>Sene: {{ new Date(order.createdAt).toLocaleString('ru-RU') }}</p>
        <p class="mb-2">Çek no.: #{{ order.code }}</p>
        <table>
          <tr>
            <th>Ady</th>
            <th>Muk.</th>
            <th>Baha</th>
            <th>Jemi</th>
          </tr>
          <tr v-for="(tr, indextr) in orderItems" :key="indextr">
            <td>{{ tr[`name_${$i18n.locale}`] }}</td>
            <td>{{ tr.type === 'product' ? tr['Orderproducts'].quantity : tr['Ordercollections'].quantity }}</td>
            <td>{{ tr.type === 'product' ? tr['Orderproducts'].price.toFixed(2) : tr['Ordercollections'].price.toFixed(2) }}</td>
            <td class="text-right">{{ tr.type === 'product' ? tr['Orderproducts'].total_price.toFixed(2) : tr['Ordercollections'].total_price.toFixed(2) }}</td>
          </tr>
        </table>
        <p class="mt-3 border border-top-black pt-2">
          <span>Eltip bermek hyzmady:</span>
          <span class="float-right" style="font-weight: 600;">{{ order.delivery_cost }}TMT</span>
        </p>
        <p>
          <span>JEMI:</span>
          <span class="float-right" style="font-weight: 600;">{{ order.total_price }}TMT</span>
        </p>
        <p class="my-3 text-center">Sargydyňyz üçin sag boluň!</p>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select'
export default {
  components: {
    vSelect
  },
  data () {
    return {
      order: { order_products: [], order_collections: [] },
      carriers: [],
      selectedCarrier: '',
      selectedItem: null,
      itemType: null,
      itemQt: null,
      activePrompt: false,
      isRejecting: '',
      isNotDelivered: '',
      isLoading: true,
      addingItem: false,
      note: '',
      deletingItem: {},
      products: []
    }
  },
  watch: {
    itemType () {
      this.selectedItem = null
      this.itemQt = null
      this.products = []
    }
  },
  computed: {
    filteredCarriers () { return this.order.delivery_type === 'market' ? this.carriers.filter(carrier => !carrier.isExpress) : this.carriers.filter(carrier => carrier.isExpress) },
    orderItems () {
      let items = []
      items = items.concat(this.order.order_products.map(el => ({ ...el, type: 'product' })))
      items = items.concat(this.order.order_collections.map(el => ({ ...el, type: 'collection' })))
      return items
    },
    discount () {
      let total = 0
      this.order.order_products.forEach(el => {
        if (el['Orderproducts'].discount_type) {
          switch (el['Orderproducts'].discount_type) {
          case 'bonused':
            total += (Math.floor(el['Orderproducts'].quantity / (el['Orderproducts'].discount_required + el['Orderproducts'].discount_bonus)) * el['Orderproducts'].discount_bonus) * el['Orderproducts'].price
            break
          case 'price':
            total += el['Orderproducts'].discount_amount * el['Orderproducts'].quantity
            break
          case 'percentage':
            total += (((el['Orderproducts'].price * 100) * el['Orderproducts'].discount_amount * 10) / 100000) * el['Orderproducts'].quantity
            break
          case 'promo':
            total += (((el['Orderproducts'].price * 100) * el['Orderproducts'].discount_amount * 10) / 100000) * el['Orderproducts'].quantity
            break
          }
        }
      })
      this.order.order_collections.forEach(el => {
        if (el['Ordercollections'].discount_type) {
          switch (el['Ordercollections'].discount_type) {
          case 'bonused':
            total += el['Ordercollections'].discount_bonus * el['Ordercollections'].price
            break
          case 'price':
            total += el['Ordercollections'].discount_amount * el['Ordercollections'].quantity
            break
          case 'percentage':
            total += ((((el['Ordercollections'].total_price / el['Ordercollections'].quantity) * 100) * el['Ordercollections'].discount_amount) / (100 - el['Ordercollections'].discount_amount) / 100) * el['Ordercollections'].quantity
            break
          case 'promo':
            total += ((((el['Ordercollections'].total_price / el['Ordercollections'].quantity) * 100) * el['Ordercollections'].discount_amount) / 100 / 100) * el['Ordercollections'].quantity
            break
          }
        }
      })
      return total.toFixed(2)
    },
    subtotal () {
      let subtot = 0
      this.order.order_products.forEach(el => { subtot += el['Orderproducts'].price * el['Orderproducts'].quantity })
      this.order.order_collections.forEach(el => { subtot += el['Ordercollections'].price * el['Ordercollections'].quantity })
      return subtot.toFixed(2)
    }
  },
  methods: {
    async fetchData () {
      await this.$http.get(`/orders/items/${this.$route.params.id}`).then(response => {
        response.data.order_products.map((el) => {
          if (el['Orderproducts'].discount_type && el['Orderproducts'].discount_type !== 'bonused') {
            switch (el['Orderproducts'].discount_type) {
            case 'price':
              el['Orderproducts'].price += el['Orderproducts'].discount_amount
              el.name = `${el.name_tm} (-${el['Orderproducts'].discount_amount}m)`
              break
            case 'percentage':
              el['Orderproducts'].price += ((el['Orderproducts'].price * el['Orderproducts'].discount_amount) / (100 - el['Orderproducts'].discount_amount))
              el.name = `${el.name_tm} (-${el['Orderproducts'].discount_amount}%)`
              break
            case 'promo':
              el['Orderproducts'].price += ((el['Orderproducts'].price * el['Orderproducts'].discount_amount) / (100 - el['Orderproducts'].discount_amount))
              el.name = `${el.name_tm} (-${el['Orderproducts'].discount_amount}%)`
              break
            }
          } else if (el['Orderproducts'].discount_type && el['Orderproducts'].discount_type === 'bonused') {
            el.name = `${el.name_tm} (${el['Orderproducts'].discount_required}+${el['Orderproducts'].discount_bonus})`
          }
        })
        response.data.order_collections.map((el) => {
          if (el['Ordercollections'].discount_type && el['Ordercollections'].discount_type !== 'bonused') {
            switch (el['Ordercollections'].discount_type) {
            case 'price':
              el['Ordercollections'].price += el['Ordercollections'].discount_amount
              el.name = `${el.name_tm} (-${el['Ordercollections'].discount_amount}m)`
              break
            case 'percentage':
              el['Ordercollections'].price += ((el['Ordercollections'].price * el['Ordercollections'].discount_amount) / (100 - el['Ordercollections'].discount_amount))
              el.name = `${el.name_tm} (-${el['Ordercollections'].discount_amount}%)`
              break
            case 'promo':
              el['Ordercollections'].price += ((el['Ordercollections'].price * el['Ordercollections'].discount_amount) / (100 - el['Ordercollections'].discount_amount))
              el.name = `${el.name_tm} (-${el['Ordercollections'].discount_amount}%)`
              break
            }
          } else if (el['Ordercollections'].discount_type && el['Ordercollections'].discount_type === 'bonused') {
            el.name = `${el.name_tm} (${el['Ordercollections'].discount_required}+${el['Ordercollections'].discount_bonus})`
          }
        })
        this.order = response.data
        this.isLoading = false
      })
    },
    async fetchCarriers () {
      await this.$http.get('/carriers', { params: { isBlocked: false } })
        .then(res => { this.carriers = res.data.data })
        .catch(() => { this.notify('error', this.$t('notify.error'), this.$t('notify.errOccured')) })
    },
    confirmDeleteItem (uuid, type) {
      this.deletingItem.uuid = uuid
      this.deletingItem.type = type
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('delete'),
        text: this.$t('confirmDelete'),
        accept: this.deleteItem,
        acceptText: this.$t('submit'),
        cancelText: this.$t('cancel')
      })
    },
    deleteItem () {
      this.$http.patch('/orders/remove-item', { type: this.deletingItem.type, uuid: this.deletingItem.uuid })
        .then(() => {
          this.fetchData()
          this.notify('success', this.$t('notify.success'), `${this.$t('product')} ${this.$t('notify.sRemoved')}`)
        })
        .catch(() => {
          this.notify('error', this.$t('notify.error'), this.$t('notify.errOccured'))
        })
    },
    sentOrder (id) {
      this.activeOrder = id
      this.activePrompt = true
      this.isRejecting = false
      this.isNotDelivered = false
    },
    submitSent () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.patch(`/orders/edit/${this.activeOrder}`, { status: 'sent', carrierId: this.selectedCarrier })
            .then(() => {
              this.fetchData()
              this.activePrompt = false
              this.activeOrder = ''
              this.isRejecting = ''
              this.notify('success', this.$t('notify.success'), `${this.$t('order')} ${this.$t('notify.sSent')}`)
            }).catch(() => {
              this.notify('error', this.$t('notify.error'), this.$t('notify.errOccured'))
            })
        }
      })
    },
    confirmReject (code, id) {
      this.activeOrder = id
      this.isRejecting = code
      this.activePrompt = true
    },
    confirmNotDeliver (code, id) {
      this.activeOrder = id
      this.isNotDelivered = code
      this.activePrompt = true
    },
    confirm (e, code, uuid) {
      this.activeOrder = uuid
      const color = e === 'reject' || e === 'not_delivered' ? 'danger' : 'primary'
      let title = ''
      let text = ''
      let accept = ''
      let acceptText = ''
      const cancelText = this.$t('cancel')
      if (e === 'accept') {
        title = this.$t('orderPage.confirmAccept')
        text = `${this.$t('orderPage.acceptingOrder')} ${code}`
        accept = this.acceptOrder
        acceptText = this.$t('orderPage.accept')
      }
      if (e === 'deliver') {
        title = this.$t('orderPage.confirmDeliver')
        text = `${this.$t('orderPage.deliveringOrder')} ${code}`
        accept = this.deliverOrder
        acceptText = this.$t('submit')
      }
      this.$vs.dialog({ type: 'confirm', color, title, text, accept, acceptText, cancelText })
    },
    acceptOrder () {
      this.$http.patch(`/orders/edit/${this.activeOrder}`, { status: 'accepted' })
        .then(() => {
          this.fetchData()
          this.notify('success', this.$t('notify.success'), `${this.$t('order')} ${this.$t('notify.sAccepted')}`)
        })
        .catch(() => {
          this.notify('error', this.$t('notify.error'), this.$t('notify.errOccured'))
        })
    },
    deliverOrder () {
      this.$http.patch(`/orders/edit/${this.activeOrder}`, { status: 'delivered' })
        .then(() => {
          this.fetchData()
          this.notify('success', this.$t('notify.success'), `${this.$t('order')} ${this.$t('notify.sDelivered')}`)
        })
        .catch(() => {
          this.notify('error', this.$t('notify.error'), this.$t('notify.errOccured'))
        })
    },
    rejectOrder () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.patch(`/orders/edit/${this.activeOrder}`, { status: this.isRejecting ? 'rejected' : 'not_delivered', note: this.note })
            .then(() => {
              this.fetchData()
              this.activePrompt = false
              this.activeOrder = ''
              this.isRejecting = ''
              this.note = ''
              this.notify('success', this.$t('notify.success'), this.isRejecting ? `${this.$t('order')} ${this.$t('notify.sReject')}` : `${this.$t('order')} ${this.$t('notify.sNotDelivered')}`)
            }).catch(() => {
              this.notify('error', this.$t('notify.error'), this.$t('notify.errOccured'))
            })
        }
      })
    },
    cancel () {
      this.clearValidator()
      this.activePrompt = false
      this.addingItem = false
      this.selectedItem = null
      this.itemQt = null
      this.itemType = null
      this.activeOrder = ''
      this.isRejecting = ''
    },
    async getItems (e) {
      if (!this.child_type && e.length > 2) {
        await this.$http.get(`/${this.itemType}s`, { params: { ...this.filter, type: this.order.delivery_type, isActive: true, keyword: e } }).then(response => {
          this.products = response.data.data.map(el => ({ ...el, name_tm: `${el.name_tm} (${el.unordered} ${this.$t('pcs')})`}))
        })
      }
    },
    addOrderItem () {
      this.activePrompt = true
      this.addingItem = true
    },
    async submitAddItem () {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          const data = { order_uuid: this.order.uuid, type: this.itemType, uuid: this.selectedItem.uuid, quantity: this.itemQt }
          await this.$http.post('/orders/add-item', data).then((res) => {
            if (res.status === 200) {
              this.fetchData()
              this.clearValidator()
              this.activePrompt = false
              this.addingItem = false
              this.selectedItem = null
              this.itemQt = null
              this.itemType = null
              this.notify('success', this.$t('notify.success'), `${this.$t('product')} ${this.$t('notify.sAdded')}`)
            }
          }).catch(() => {
            this.notify('error', this.$t('notify.error'), this.$t('notify.errOccured'))
          })
        }
      })
    },
    getOrderStatusColor (status) {
      switch (status) {
      case 'pending':   return 'warning'
      case 'accepted': return 'primary'
      case 'delivered': return 'success'
      case 'rejected': return 'danger'
      case 'not_delivered':  return 'danger'
      default: return 'primary'
      }
    },
    clearValidator () {
      this.$validator.pause()
      this.$validator.attach()
      this.$nextTick(() => {
        this.$validator.errors.clear()
        this.$validator.fields.items.forEach(field => field.reset())
        this.$validator.fields.items.forEach(field => this.errors.remove(field))
        this.$validator.resume()
      })
    },
    thermalPrint () {
      window.print()
    },
    flaskPrint () {
      axios.post('http://127.0.0.1:5000', {
        order: {
          ...this.order,
          discount: this.discount,
          subtotal: this.subtotal,
          order_products: null,
          order_collections: null
        },
        items: this.orderItems
      }).then(() => this.notify('success', this.$t('notify.success'))).catch(() => this.notify('error', this.$t('notify.error')))
    },
    notify (type, title, text) {
      this.$vs.notify({
        title,
        text,
        iconPack: 'feather',
        icon: type === 'success' ? 'icon-check-circle' : 'icon-alert-circle',
        color: type === 'success' ? 'primary' : 'danger'
      })
    }
  },
  async created () {
    await this.fetchData()
    if (this.order.status === 'pending' || this.order.status === 'accepted') await this.fetchCarriers()
  },
  mounted () {
    this.$emit('setAppClasses', 'invoice-page')
  }
}
</script>

<style lang="scss">
.invoice-print-text {
  display: none;
}
@media print {
  body, #app {
    height: unset !important;
    min-height: unset !important;
  }
  .layout--main .main-vertical .navbar-floating .footer-static {
    height: min-content !important;
    min-height: min-content !important;
  }
  #content-area, .content-area-reduced {
    margin-left: 0px !important;
    height: min-content !important;
    min-height: min-content !important;
    .content-wrapper {
      min-height: min-content !important;
    }
    #content-overlay {
      height: min-content !important;
    }
    footer.the-footer {
      display: none !important;
    }
  }
  .vs-content-sidebar .v-nav-menu .items-no-padding, .vs-sidebar, .vs-sidebar-primary, .vs-sidebar-parent, .vs-sidebar-reduceNotRebound, .vs-sidebar--items {
    display: none !important;
  }
  .vx-navbar-wrapper, .navbar-default, .vs-navbar, .vx-navbar, .navbar-custom, .navbar-skelton, .vs-navbar-null {
    display: none !important;
    background: none !important;
  }
  .router-content {
    margin-top: 0px !important;
    .router-header {
      display: none !important;
    }
  }
  #order-show-page {
    .order-header, .order-details {
      display: none !important;
    }
  }
  .invoice-print-text {
    font-size: 12px !important;
    font-family: arial, sans-serif;
    margin-top: -20px;
    margin-left: -15px;
    display: block;
    padding-bottom: 100px;
    border-bottom: 1px dashed #dddddd;
    width: 270px;
    table {
      font-family: arial, sans-serif;
      font-size: 11px !important;
      // border-collapse: collapse;
      width: 100%;
    }
    td, th {
      font-size: 11px !important;
      // border: 1px solid #dddddd;
      text-align: left;
      padding: 0px 3px 0px 0px;
    }
  }
}
</style>